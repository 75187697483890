import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/home-layout"
import SEO from "../components/seo"
import { Grid, Row, Col } from "react-flexbox-grid"
import "typeface-playfair-display"
import Img from "gatsby-image"
import style from "./home.module.css"
import GridGenerator from "../components/gridGenerator"
import { head } from "ramda"

const IndexPage = ({ data }) => {
  const latestPodcast = head(data.allWordpressPost.edges)
  const latestBlog = head(data.allWordpressWpBlog.edges)
  console.log("data", data)
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <Grid fluid>
        <Row className={style.aboutRow}>
          <Col>
            <div className={style.aboutCard}>
              <Row>
                <Col sm={12} md={12} lg={8}>
                  <h3>Hi Diet Rioters!</h3>
                  <p>
                    Two dietitians revolting against diet culture and promoting
                    positive relationships with food through intuitive eating.
                    Brooke and Alyssa talk through nutrition topics each week.
                    They talk candidly about their experiences, philosophies,
                    and give some tangible advice to help you navigate the world
                    of nutrition, health and wellness.
                  </p>
                  <Link to={`/about-us`} className={style.moreLink}>
                    Learn more about us →
                  </Link>
                </Col>
                <Col sm={12} md={12} lg={4}>
                  <div>
                    <Img fluid={data.alyssaPic.childImageSharp.fluid} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className={style.featureRow}>
          <Col
            key={latestBlog.node.title}
            sm={12}
            md={12}
            lg={6}
            className={style.latestItem}
          >
            <div className={style.featureCard}>
              <Link
                to={`/${latestBlog.node.path}`}
                style={{
                  display: "flex",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                <div className={style.postPreview}>
                  <h3
                    dangerouslySetInnerHTML={{ __html: latestBlog.node.title }}
                    style={{ marginBottom: 15 }}
                  />
                  <p className={style.postDate}>{latestBlog.node.date}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: latestBlog.node.excerpt,
                    }}
                  />
                  <Link
                    to={`/${latestBlog.node.path}`}
                    className={style.moreLink}
                  >
                    Continue Reading →
                  </Link>
                </div>
              </Link>
            </div>
          </Col>
          <Col
            key={latestPodcast.node.title}
            sm={12}
            md={12}
            lg={6}
            className={style.latestItem}
          >
            <div className={style.featureCard}>
              <Link
                to={`/${latestPodcast.node.path}`}
                style={{
                  display: "flex",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                <div>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: latestPodcast.node.title,
                    }}
                    style={{ marginBottom: 15 }}
                  />
                  <p className={style.postDate}>{latestPodcast.node.date}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: latestPodcast.node.excerpt,
                    }}
                  />
                  <Link
                    to={`/${latestPodcast.node.path}`}
                    className={style.moreLink}
                  >
                    Listen Now →
                  </Link>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className={style.blogHeader}>Latest from the blog</h3>
          </Col>
        </Row>
        <Row>
          <Col className={style.blogSection}>
            <GridGenerator cols={2}>
              {data.allWordpressWpBlog.edges
                .filter((_, i) => i > 0)
                .map(post => (
                  <div>
                    <div className={style.postPreview}>
                      <Link
                        to={`/${post.node.path}`}
                        style={{
                          display: "flex",
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        <h3
                          dangerouslySetInnerHTML={{ __html: post.node.title }}
                          style={{ marginBottom: 15 }}
                        />
                      </Link>
                      <p className={style.postDate}>{post.node.date}</p>
                      <div
                        dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                      />
                      <Link
                        to={`/${post.node.path}`}
                        className={style.moreLink}
                      >
                        Continue Reading →
                      </Link>
                    </div>
                  </div>
                ))}
            </GridGenerator>
          </Col>
        </Row>
        <Row className={style.rowBlogBtn}>
          <Col>
            <Link className="button-primary" to={`/blog/1`}>
              More from the blog →
            </Link>
          </Col>
        </Row>
        {/*}
        <Row>
          <Col>
            <h3 className={style.blogHeader}>Latest Instagram</h3>
          </Col>
        </Row>
        <Row className={style.instaRow}>
          {data.allInstaNode.edges
            .filter((x, i) => i < 4)
            .map(({ node }) => (
              <Col sm={12} md={12} lg={3} className={style.instagramCol}>
                <a
                  href={`https://www.instagram.com/p/${node.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fluid={node.localFile.childImageSharp.fluid}
                    alt="Instagram post"
                  />
                </a>
              </Col>
            ))}
        </Row>
        <Row>
          <Col>
            <a
              className="button-primary"
              href="https://www.instagram.com/diet.rioters/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow us on Instagram →
            </a>
          </Col>
        </Row>
        */}
      </Grid>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          title
          excerpt
          slug
          path
          author {
            name
          }
          categories {
            slug
          }
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allWordpressWpBlog {
      edges {
        node {
          id
          title
          excerpt
          path
          content
          status
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    alyssaPic: file(relativePath: { eq: "dietriot.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brookePic: file(relativePath: { eq: "brooke-miller.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

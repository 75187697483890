/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Grid, Row, Col } from "react-flexbox-grid"
import Header from "./header"

import style from "./home-layout.module.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header siteTitle="Mama & Me RD" isMargin />

      <header className={style.headerSection}>
        <div className="main-container">
          <Grid>
            <Row>
              <Col md={9}>
                <div>
                  <h2 className={style.siteTitle}>
                    <span>
                      ditching diets and finding food freedom together
                    </span>
                  </h2>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </header>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
      </div>
      <footer>
        <div className="main-container">
          © {new Date().getFullYear()} Diet Riot LLC
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
